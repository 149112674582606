body {
  margin: 0;
  font-family: "Helvetica Neue LT Std", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
  font-family: headerFont;
  src: url("./fonts/Cookie-Regular.ttf");
}

@font-face {
  font-family: tentFont;
  src: url("./fonts/YsabeauSC-VariableFont_wght.ttf");
}